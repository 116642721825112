<template>
  <div>
    <v-form ref="form" @submit.prevent="onSubmit">
      <v-row>
        <v-col :cols="12" :sm="6">
          <v-text-field
            :label="$t('FirstName')"
            v-model="form.first_name"
            :error-messages="firstNameErrors"
            @blur="$v.form.first_name.$touch()"
            @input="$v.form.first_name.$touch()"
            required
          >
          </v-text-field>
        </v-col>

        <v-col :cols="12" :sm="6">
          <v-text-field
            :label="$t('LastName')"
            v-model="form.last_name"
            :error-messages="lastNameErrors"
            @blur="$v.form.last_name.$touch()"
            @input="$v.form.last_name.$touch()"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :sm="6">
          <v-text-field
            :label="$t('EMail')"
            v-model="form.email"
            type="email"
            :error-messages="emailErrors"
            @blur="$v.form.email.$touch()"
            @input="$v.form.email.$touch()"
            required
          >
          </v-text-field>
        </v-col>

        <v-col :cols="12" :sm="6">
          <v-select
            :items="localeOptions"
            flat
            :label="$t('Language')"
            v-model="form.language"
            :error-messages="localeErrors"
            @blur="$v.form.language.$touch()"
            @input="$v.form.language.$touch()"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" class="text-right">
          <v-btn color="primary" class="mt-3" type="submit" depressed :loading="isLoading">{{ $t('Save') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar.isVisible" top :color="snackbar.color" text>
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar.isVisible = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as TRANSLATIONS_MODULE } from '@/store/modules/translations';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';

export default {
  name: 'FormUserEdit',

  mixins: [validationMixin],

  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },

  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      language: { required }
    }
  },

  computed: {
    ...mapGetters({
      company: `${USER_MODULE}/company`,
      currentUser: `${USER_MODULE}/currentUser`,
      isLoading: `${USER_MODULE}/isLoading`
    }),

    isCurrentUser() {
      const currentUserId = get(this.currentUser, 'id');
      const userId = get(this.data, 'id');

      return currentUserId === userId;
    },

    isEdit() {
      let result = false;
      const id = get(this.data, 'id');
      if (id) {
        result = true;
      }
      return result;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) {
        return errors;
      }
      if (!this.$v.form.first_name.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) {
        return errors;
      }
      if (!this.$v.form.last_name.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    localeErrors() {
      const errors = [];
      if (!this.$v.form.language.$dirty) {
        return errors;
      }
      if (!this.$v.form.language.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) {
        return errors;
      }
      if (!this.$v.form.email.email) {
        errors.push(this.$t('Wrong format'));
      }
      if (!this.$v.form.email.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    }
  },

  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        title: '',
        language: 'de-DE',
        status: 'active'
      },
      localeOptions: [
        { value: 'de-DE', text: this.$t('German') },
        { value: 'en-US', text: this.$t('English') }
      ],
      snackbar: {
        isVisible: false,
        message: '',
        color: ''
      }
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.data && this.data.id) {
        this.form = Object.assign({}, this.data);
      }

      // const companyId = get(this.company, 'id');
      // const roleId = get(this.currentUser, 'role.id');
      // if (companyId) {
      //   this.form.company = companyId;
      // }

      // if (roleId) {
      //   this.form.role = roleId;
      // }
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        // Show validation errors
      } else {
        // do your submit logic here
        this.doSave();
      }
    },

    async doSave() {
      if (!this.isEdit) {
        return false;
      }

      const ACTION = `${USER_MODULE}/UPDATE_CURRENT_USER`;
      const PAYLOAD = Object.assign({}, this.form);

      const result = await this.$store.dispatch(ACTION, PAYLOAD);
      const { id } = result;

      // Success
      if (id) {
        this.handleSuccessResponse(result);
      }
    },

    handleSuccessResponse(result) {
      let lang = 'en';
      const language = get(result, 'language');

      if (language && language.length > 2) {
        lang = language.slice(0, 2);
      }

      this.$store.dispatch(`${TRANSLATIONS_MODULE}/GET_TRANSLATION`, lang);
      this.$store.dispatch(`${USER_MODULE}/GET_CURRENT_USER`);
      this.$store.dispatch(`${CUSTOMER_AIRCRAFTS_MODULE}/GET_AIRCRAFTS`);
    }
  },

  watch: {
    data() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped></style>
