<template>
  <div class="settings__company">
    <!-- <FormCompanyEdit :data="company"></FormCompanyEdit> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import FormCompanyEdit from '@/components/Form/FormCompanyEdit.vue';

export default {
  name: 'SettingsCompany',

  components: {
    // FormCompanyEdit
  },

  computed: {
    ...mapGetters({
      company: 'user/company'
    })
  }
};
</script>
