<template>
  <div>
    <v-alert class="mt-4" border="left" :value="alert.isVisible" :type="alert.type" text :color="alert.color">{{ alert.message }} </v-alert>

    <v-form ref="form" @submit.prevent="onSubmit">
      <v-row>
        <v-col :cols="12">
          <v-text-field
            :label="$t('Password')"
            v-model="form.password"
            type="password"
            :error-messages="passwordErrors"
            @blur="$v.form.password.$touch()"
            required
          >
          </v-text-field>
        </v-col>

        <v-col :cols="12">
          <v-text-field
            :label="$t('RepeatPassword')"
            v-model="form.password2"
            type="password"
            :error-messages="password2Errors"
            @blur="$v.form.password2.$touch()"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" class="text-right">
          <v-btn color="primary" class="mt-3" type="submit" depressed :loading="isLoading">{{ $t('ChangePassword') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Display status messages -->
    <v-snackbar v-model="snackbar.isVisible" :color="snackbar.color" text>
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar.isVisible = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';

export default {
  name: 'FormChangePassword',

  mixins: [validationMixin],

  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        minLength: minLength(6)
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: `${USER_MODULE}/currentUser`,
      isLoading: `${USER_MODULE}/isLoading`
    }),

    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) {
        return errors;
      }
      if (!this.$v.form.password.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      if (!this.$v.form.password.minLength) {
        errors.push(this.$t('text.PasswordTooShort'));
      }
      return errors;
    },

    password2Errors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) {
        return errors;
      }
      if (!this.$v.form.password.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      if (!this.$v.form.password2.minLength) {
        errors.push(this.$t('text.PasswordTooShort'));
      }
      if (this.$v.form.password.$model !== this.$v.form.password2.$model) {
        errors.push(this.$t('text.PasswordDoNotMatch'));
      }
      return errors;
    }
  },

  data() {
    return {
      alert: {
        isVisible: false,
        message: '',
        color: '',
        type: 'success'
      },
      form: {
        id: null,
        password: '',
        password2: ''
      },
      snackbar: {
        isVisible: false,
        message: '',
        color: ''
      }
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const { id } = this.currentUser;
      if (id) {
        this.form.id = id;
      }
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        // Show validation errors
      } else {
        // do your submit logic here
        this.doSave();
      }
    },

    async doSave() {
      const { password } = this.form;
      const result = await this.$store.dispatch(`${USER_MODULE}/CHANGE_PASSWORD`, { password });
      const { code, message, data } = result;

      // Error
      if (code || message) {
        this.snackbar.message = message;
        this.snackbar.isVisible = true;
        this.snackbar.color = 'error';
        return false;
      }

      // Success
      if (data) {
        this.alert.message = this.$t('text.PasswordChangeSuccess');
        this.alert.isVisible = true;

        this.$v.$reset();
        this.form.password = null;
        this.form.password2 = null;
      }
    }
  },

  watch: {
    data() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped></style>
