<template>
  <div class="switch-role">
    <v-list class="flex justify-center">
      <v-list-item v-for="role in roles" :key="role.id" class="switch-role__item" :class="[{ 'is-selected': selectedRole.id === role.id }]">
        <label :for="role.id" class="switch-role__item-label">
          <span class="switch-role__item-label-text">{{ $t(role.name) }}</span>
          <input type="radio" :id="role.id" :value="role" v-model="selectedRole" />
        </label>
      </v-list-item>
    </v-list>

    <p class="mt-4 text-center text-body-1">{{ $t('text.switchRole') }}</p>

    <div class="text-right">
      <v-btn
        color="primary"
        depressed
        :ripple="false"
        :disabled="!selectedRole.id || selectedRole.id === user.role.id"
        :loading="isLoading"
        @click.prevent="doSwitchRole"
        >{{ $t('btn.save') }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import { refreshToken } from '@/services/auth';
import { switchUserRole } from '@/services/user';

export default {
  name: 'SwitchRole',

  computed: {
    ...mapGetters({
      user: `${USER_MODULE}/currentUser`,
      roles: `${USER_MODULE}/currentUserRoles`
    })
  },

  data() {
    return {
      isLoading: false,
      selectedRole: {
        id: null,
        name: null,
        description: null
      }
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async doSwitchRole() {
      try {
        this.isLoading = true;
        await switchUserRole(this.user.id, this.selectedRole.id);
        await refreshToken();
        window.location.reload();
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    init() {
      if (this.user?.role) {
        this.selectedRole = {
          id: this.user.role.id,
          name: this.user.role.name,
          description: this.user.role.description
        };
      }
    }
  },

  watch: {
    'user.role'() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-role {
  &__item {
    flex-direction: column;
    max-width: 20rem;
    min-height: auto;
  }

  &__item-label {
    border: 1px solid var(--c-medium-gray);
    border-radius: var(--border-radius-base);
    background-color: var(--c-white);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 1.4rem 0.625rem;

    .is-selected & {
      border-color: var(--c-brand);
      background-color: rgba(var(--c-brand-rgb), 0.07);
    }
  }

  &__item-label-text {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;

    .is-selected & {
      color: var(--c-brand);
    }
  }
}
</style>
