<template>
  <div>
    <SwitchRole class="mt-10" />
  </div>
</template>

<script>
import SwitchRole from '@/components/Select/SwitchRole.vue';

export default {
  name: 'SettingsSwitchRole',

  components: {
    SwitchRole
  }
};
</script>

<style lang="scss" scoped></style>
