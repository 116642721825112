<template>
  <div>
    <FormChangePassword :user-id="userId" v-if="userId" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import FormChangePassword from '@/components/Form/FormSettings/FormChangePassword.vue';

export default {
  name: 'SettingsChangePassword',

  components: {
    FormChangePassword
  },

  computed: {
    ...mapGetters({
      currentUser: `${USER_MODULE}/currentUser`
    }),

    userId() {
      return get(this.currentUser, 'id');
    }
  }
};
</script>

<style lang="scss" scoped></style>
