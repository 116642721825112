<template>
  <div>
    <FormUserEdit :data="user" v-if="userId"></FormUserEdit>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import FormUserEdit from '@/components/Form/FormSettings/FormUserEdit.vue';

export default {
  name: 'SettingsUser',

  components: {
    FormUserEdit
  },

  computed: {
    ...mapGetters({
      user: `${USER_MODULE}/currentUser`
    }),

    userId() {
      return get(this.user, 'id');
    }
  },

  data() {
    return {
      loading: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (!this.userId) {
        this.requestUser();
      }
    },

    requestUser() {
      this.loading = true;

      return this.$store
        .dispatch(`${USER_MODULE}/GET_CURRENT_USER`)
        .then(response => {
          this.loading = false;
          return response;
        })
        .catch(err => {
          this.loading = false;
          return err;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
